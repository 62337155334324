import React, { useEffect } from "react"
import { navigate } from 'gatsby'
import Helmet from "react-helmet"
import Header from "../components/Header"
import BannerSection from "../components/BannerSection"
import { getPageData } from "../lib/API"
import { useData } from "../lib/hooks"
import Loader from 'react-loader-spinner'


const Error = () => {
  useEffect(() => navigate('/'), [])
  return (
    <span style={{ textAlign: "center", width: '100%', height: '100vh' }}>
      {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
    </span>
  )
}

export default () => {
    const [ path ] = typeof window !== 'undefined' ? window.location.href.split( '/' ).slice( -1 ) : [ '' ]
    console.log(path)
    const pageData = useData( getPageData, `/${path}`, {}  )
    const siteTitle = "Blackheath and Charlton Baptist Church"
    
    const { header, banners } =  pageData && Object.keys(pageData).length > 0 ? pageData : { header: null, banners: null }
    let bannerStyle = "style2"

    if( pageData && Object.keys(pageData).length > 0 )
        return ( 
            <>
                <Helmet title={siteTitle} />
                {header && <Header images={header.images} headerTitle={header.title} headerText={header.text} tint={0.4} />}
                <BannerSection banners={banners} bannerStyle={bannerStyle} />
    
            </>
        )
    else if( pageData && Object.keys(pageData).length === 0 )
        return (
            <div className='col-12' style={{width: '100%', height: '100vh', display: 'flex', 'justifyContent': 'center', alignItems: 'center'}}>
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={200}
                    width={200}
                /> 
            </div>
        )
    else
        return <Error />
}
